import React, { useCallback, useRef, useState, useEffect } from "react";
import "./RegistrationForm.css";
import { FaCamera, FaUpload } from "react-icons/fa";
import Webcam from "react-webcam";
import axios from "axios";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from "@mui/material";
import SignaturePad from "signature_pad";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import countryData from '../../country.json'
import { Container } from "react-bootstrap";

function RegistrationForm() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const webcamRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [openCondition, setOpenCondition] = useState(false)
  const [openUniqueIdDialog, setOpenUniqueIdDialog] = useState(false);
  const [uniqueIdValue, setUniqueIdValue] = useState();

  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    countrycode: "",
    user_email: "",
    country: "",
  });
  const [fileSelected, setFileSelected] = useState(null);

  useEffect(() => {
    if (formData.country) {
      const countryName = countryData.find(c_name => c_name.name == formData.country)
      setFormData({ ...formData, countrycode: countryName.dial_code })
    }else{
      setFormData({ ...formData, countrycode: "" })
    }
  }, [formData.country])

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileSelected(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPreviewImage(imageSrc);
    setCameraEnabled(false);

    const stream = webcamRef.current.video.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  }, [webcamRef]);

  const enableCamera = () => {
    setCameraEnabled(true);
  };

  const handleRetake = () => {
    setPreviewImage(null);
    enableCamera();
  };

  const handleTake = () => {
    capture();
  };

  const base64ToFile = (base64String, fileName) => {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString });
  };

  useEffect(() => {
    if (isCheckboxChecked) {
      const canvas = canvasRef.current;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);

      signaturePadRef.current = new SignaturePad(canvas);

      return () => {
        signaturePadRef.current.off();
        signaturePadRef.current = null;
      };
    }
  }, [isCheckboxChecked]);

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleClear = () => {
    signaturePadRef.current.clear();
    setSignatureDataURL(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("user_name", formData.user_name);
    data.append("user_phone", formData.user_phone);
    data.append("countrycode", formData.countrycode);
    data.append("user_email", formData.user_email);
    data.append("country", formData.country);
    data.append("admin_id", sessionStorage.getItem('admin_id'));

    if (fileSelected) {
      data.append("user_image", fileSelected);
    } else if (previewImage) {
      const name = formData.user_name;
      const selfieFile = base64ToFile(previewImage, `${name}.jpg`);
      data.append("user_image", selfieFile);
    }

    if (signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
      const signatureDataURL = signaturePadRef.current.toDataURL();
      const name = formData.user_name;
      const signatureFile = base64ToFile(signatureDataURL, `${name}.png`);
      data.append("signature", signatureFile);
    } else {
      alert("Please provide a signature.");
      return;
    }

    try {
      const response = await axios.post(
        "https://ifx-expo-server.xicsolutions.in/user/storeUserData",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData({
        user_name: "",
        user_phone: "",
        countrycode: "",
        user_email: "",
        country: "",
      });
      setFileSelected(null);
      setPreviewImage(null);
      setSignatureDataURL(null);

      setOpenUniqueIdDialog(true);
      setUniqueIdValue(response.data.unique_id);

      // setTimeout(() => {
      //   navigate("/adminIndex");
      // }, 3000);
    } catch (error) {
      setFormData({
        user_name: "",
        user_phone: "",
        countrycode: "",
        user_email: "",
        country: "",
      });
      setFileSelected(null);
      setPreviewImage(null);
      setSignatureDataURL(null);
      if (error.response && error.response.status === 400) {
        console.error("Error:", error);
      }
    } finally {
    }
  };


  return (
    <>
      <Container>
        <div className="reg-Register-form-contain">
          <div className="reg-content">
            <div className="reg-glass-container">
              <h1>Register Now</h1>
              <form id="glass-registration-form" onSubmit={handleSubmit}>
                <div className="reg-glass-form-group reg-full-width-full-name">
                  <label htmlFor="user_name">Full Name</label>
                  <input
                    type="text"
                    id="user_name"
                    name="user_name"
                    required
                    value={formData.user_name}
                    onChange={(e) => setFormData({ ...formData, user_name: e.target.value })}

                  />
                </div>
                <div className="reg-glass-form-group">
                  <label htmlFor="user_email">Email</label>
                  <input
                    type="email"
                    id="user_email"
                    name="user_email"
                    required
                    value={formData.user_email}
                    onChange={(e) => setFormData({ ...formData, user_email: e.target.value })}
                  />
                </div>
                <div className="reg-glass-form-group">
                  <label htmlFor="country">Country </label>
                  <select
                      id="country"
                      name="country"
                      className="country-name-input"
                      required
                      value={formData.country}
                      onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    >
                      <option value="">Please select country</option>
                      {countryData.map((country) => (
                        <option value={country.name}>{country.name}</option>
                      ))}
                    </select>
                </div>
                <div className="reg-glass-form-group">
                  <label htmlFor="user_phone">Phone Number</label>
                  <div className="reg-phone-input-group">
                    <select
                      id="country_code"
                      name="country_code"
                      required
                      value={formData.countrycode}
                      onChange={(e) => setFormData({ ...formData, countrycode: e.target.value })}
                      disabled
                    >
                      <option value="">code</option>
                      {countryData.map((code) => (
                        <option value={code.dial_code}>{code.dial_code}</option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="user_phone"
                      name="user_phone"
                      required
                      value={formData.user_phone}
                      onChange={(e) => setFormData({ ...formData, user_phone: e.target.value })}
                    />
                  </div>
                </div>
                <div className="reg-glass-form-group reg-one">
                  <label htmlFor="preview_image" >Preview Image</label>
                  <div className="reg-preview-container">
                    {!previewImage && !cameraEnabled && (
                      <div className="reg-placeholder-text" id="preview_image-container">
                        <p>Your preview photo will be shown here</p>
                      </div>
                    )}
                    {previewImage && (
                      <img
                        src={previewImage}
                        alt="Preview"
                        className="reg-preview-image-display"
                      />
                    )}
                    {cameraEnabled && !previewImage && (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "fill", borderRadius: "10px" }}
                        screenshotQuality={1}
                        mirrored={true}
                        imageSmoothing={true}
                      />
                    )}
                    {previewImage && (
                      <button
                        type="button"
                        className="reg-retake-button"
                        onClick={handleRetake}
                      >
                        Retake Photo
                      </button>
                    )}
                    {cameraEnabled && !previewImage && (
                      <button
                        type="button"
                        className="reg-capture-button"
                        onClick={handleTake}
                      >
                        Capture
                      </button>
                    )}
                  </div>
                </div>
                <div className="reg-glass-form-group reg-two">
                  <label htmlFor="user_image">Profile Image</label>
                  <div className="reg-user_image_input">
                    <div className="reg-upload_image reg-selfie_upload">
                      <div
                        className="reg-camera_button"
                        onClick={enableCamera}
                      >
                        <FaCamera className="reg-icon reg-camera-icon" />
                        <p >Click an image</p>
                      </div>
                    </div>
                    <div className="reg-upload_image reg-file_upload">
                      <input
                        type="file"
                        ref={fileInputRef}
                        id="file-upload"
                        className="reg-file-upload-input"
                        accept="image/*"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-upload" className="reg-file-upload-label">
                        <FaUpload className="reg-icon reg-upload-icon" />
                        <p>Upload a file</p>
                      </label>
                    </div>
                  </div>
                </div>
                
                <div className="reg-four mt-5 mt-md-0">
                <div >
                  <label className="me-2 ms-md-0 ms-2">
                    <input
                      className="agree-check-box"
                      type="checkbox"
                      id="termsCheckbox"

                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                    />
                  </label>
                  <Link component="button" variant="body2" onClick={() => setOpenCondition(true)} className="termslink ms-3" style={{ marginTop: "-7px", }}>By signing on the below, I agree to the  Terms  & Conditions</Link>
                  <div className="reg-signature">
                    <div className="reg-signaturePad">
                      {isCheckboxChecked && (
                        <div className="reg-signature-pad">
                          <canvas ref={canvasRef} className="reg-signature-canvas"></canvas>
                          <button type="button" className="reg-signature-clear-button" onClick={handleClear}>
                            Clear Signature
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  </div>
                  <button type="submit" className="reg-registeration-submit-button mt-4">Submit</button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </Container>

      <Dialog open={openCondition} onClose={() => setOpenCondition(false)} maxWidth='lg'>
        <DialogContent>
          <TermsAndConditions />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCondition(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUniqueIdDialog} onClose={() => setOpenUniqueIdDialog(false)}>
        <DialogContent>
          <div className="d-flex flex-column align-items-center">
            {/* Heading */}
            <h6 className="text-center mb-2">YOUR UNIQUE ID IS</h6>

            {/* Unique ID Display */}
            <div
              style={{
                width: '200px',
                backgroundColor: 'whitesmoke',
                color: 'black',
                textAlign: 'center', // Center the text within the box
                padding: '10px', // Add some padding to make it look nicer
                borderRadius: '5px', // Optional: Add a slight border radius for aesthetics
                marginBottom: '20px' // Add space below the box
              }}
            >
              <p className="m-0">{uniqueIdValue}</p>
            </div>

            {/* Button */}
            <Button
              onClick={() => setOpenUniqueIdDialog(false)}
              variant="contained"
              color="primary"
              style={{ textAlign: 'center' }}
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default RegistrationForm;
