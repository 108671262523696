import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  DialogTitle,
  Select,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaEdit, FaSearch, FaEye } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import "./RegistrationIndex.css";
import UpdateRegistrationForm from "../RegistrationForm/UpdateRegistrationForm/UpdateRegistrationForm";
import ViewUser from "./ViewUser/ViewUser";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const RegistrationIndex = () => {
  const admin_id = sessionStorage.getItem('admin_id');
  const navigate = useNavigate();
  const [regData, setRegData] = useState([]);

  const [openUpdate, setOpenUpdate] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [openDlt, setOpenDlt] = useState(false);
  const [dltData, setDltData] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [openScore, setOpenScore] = useState(false);
  const [scoreData, setScoreData] = useState({
    score: "",
    reg_no: "",
    admin_name: sessionStorage.getItem("admin_name"),
  });
  const [openViewUser, setOpenViewUser] = useState(false);
  const [viewUserData, setViewUserData] = useState(false);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    axios
      .get(`https://ifx-expo-server.xicsolutions.in/user/getUsersData`)
      .then((res) => {
        setRegData(res.data);
        setFilteredDatas(res.data);
      })
      .catch((err) => {
        console.log("Registration Data is not fetched.", err);
      });
  }, [openUpdate, openDlt]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = regData.filter(
      (item) =>
        item.user_name.toLowerCase().includes(lowercasedQuery) ||
        item.email.toLowerCase().includes(lowercasedQuery) ||
        item.phone_no.toLowerCase().includes(lowercasedQuery) ||
        item.country.toLowerCase().includes(lowercasedQuery) ||
        item.unique_id.toLowerCase().includes(lowercasedQuery) ||
        item.created_at.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredDatas(filtered);
  }, [searchQuery, regData]);

  const handleView = (id) => {
    const selectData = regData.find((reg) => reg.user_id === id);
    if (selectData) {
      setOpenViewUser(true);
      setViewUserData(selectData);
    }
  };

  const handleUpdate = (id) => {
    const selectData = regData.find((reg) => reg.user_id === id);
    if (selectData) {
      setOpenUpdate(true);
      setUpdateData(selectData);
    }
  };

  const handleUpdateSuccessMsg = () =>{
    setOpenUpdate(false);
    toast.success("User Data Updated Successfully.",{autoClose:3000})
  }

  const handleDlt = (id) => {
    if (id) {
      setDltData(id);
      setOpenDlt(true);
    }
  };

  const handleConfirmDlt = () => {
    if (dltData) {
      axios
        .delete(
          `https://ifx-expo-server.xicsolutions.in/user/deleteUserData/${dltData}`
        )
        .then((res) => {
          setOpenDlt(false);
          toast.success("User Data Deleted Successfully.",{autoClose:3000})
        })
        .catch((err) => {
          console.log("Error Failed to delete data.", err);
        });
    }
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(regData.map((reg) => reg.user_id));
    }
    setSelectAll(!selectAll);
  };

  const handleExport = () => {
    if (selectedRows.length === 0) {
      toast.warning("Please select data .", { autoClose: 3000 });
    } else {
      const exportData = regData
        .filter((reg) => selectedRows.includes(reg.user_id))
        .map((reg, index) => ({
          "S.No": index + 1,
          "Unique Id": reg.unique_id,
          Name: reg.user_name,
          "Mobile No": reg.phone_no,
          "Mail Id": reg.email,
          "Mail Id": reg.email,
          admin_name: reg.admin_name,
        }));

      const worksheet = XLSX.utils.json_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Registration Data");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "registration_data.xlsx");
    }
  };

  const handleChangeDataPerPage = (e) => {
    const newDataPerPage =
      e.target.value === regData.length
        ? regData.length
        : parseInt(e.target.value, 10);
    setDataPerPage(newDataPerPage);
    setCurrentPage(1);
  };

  const firstIndexOfData = (currentPage - 1) * dataPerPage;
  const lastIndexOfData = currentPage * dataPerPage;
  const currentData = filteredDatas.slice(firstIndexOfData, lastIndexOfData);

  const handleScoreChange = (e) => {
    setScoreData({ ...scoreData, score: e.target.value }); // Update score in the state
  };

  const handleScoreSubmit = () => {
    axios
      .post(
        `https://ifx-expo-server.xicsolutions.in/score/addScore/${scoreData.user_id}`,
        scoreData
      )
      .then((res) => {
        navigate("/scoreBoard");
      })
      .catch((err) => {
        console.log("Error failed to add score", err);
      });
  };

  const handleActions = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  return (
    <div className="registration-index-section">
      <Container>
        <ToastContainer position="top-right" />
        <Row>
          <Col xs={12}>
            <h1
              className="text-center"
              style={{
                color: "#6000d9",
                fontWeight: "bold",
                fontFamily: "Gellix",
              }}
            >
              REGISTRATION INDEX
            </h1>
          </Col>
        </Row>

        {sessionStorage.getItem("Role") == "super-admin" ? (
          <Row className="mt-5 mb-5">
            <Col
              xs={12}
              md={4}
              lg={4}
              className="d-flex justify-content-lg-start justify-content-md-start justify-content-center mt-md-0 mt-3"
            >
              <Button
                onClick={() => navigate(`/regForm/${admin_id}`)}
                className="navigate-btns"
              >
                Add
              </Button>

              <Button
                onClick={() => navigate("/adminIndex")}
                className="navigate-btns ms-3"
              >
                Home
              </Button>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={4}
              className="d-flex justify-content-lg-start justify-content-md-end justify-content-center mt-md-0 mt-3"
            >
              <div className="search-container">
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <FaSearch className="searchicon" />
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={2}
              className="d-flex justify-content-lg-start justify-content-md-end justify-content-center mt-md-0 mt-3"
            >
              <Button onClick={handleExport} className="navigate-btns">
                Export
              </Button>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={2}
              className="d-flex justify-content-lg-end justify-content-md-center justify-content-center mt-md-5 mt-lg-0 mt-3"
            >
              <Select
                className="pagination-btn"
                value={dataPerPage}
                onChange={handleChangeDataPerPage}
              >
                <MenuItem value={20}>20 Per Page</MenuItem>
                <MenuItem value={30}>30 Per Page</MenuItem>
                <MenuItem value={50}>50 Per Page</MenuItem>
                <MenuItem value={regData.length}>All Per Page</MenuItem>
              </Select>
            </Col>
          </Row>
        ) : (
          <Row className="mt-5 mb-5">
            <Col
              xs={12}
              md={5}
              lg={4}
              className="d-flex justify-content-center justify-content-lg-start justify-content-md-start mt-md-0 mt-3"
            >
              <Button
                onClick={() => navigate("/regForm")}
                className="navigate-btns"
              >
                Add
              </Button>
              <Button
                onClick={() => navigate("/adminIndex")}
                className="navigate-btns ms-3 "
              >
                Home
              </Button>
            </Col>
            <Col
              xs={12}
              md={4}
              lg={4}
              className="d-flex justify-content-lg-center justify-content-md-start justify-content-center  mt-md-0 mt-3"
            >
              <div className="search-container">
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Search..."
                    aria-label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <FaSearch className="searchicon" />
                </div>
              </div>
            </Col>

            <Col
              xs={12}
              md={3}
              lg={4}
              className="d-flex justify-content-lg-end justify-content-md-end justify-content-center  mt-md-0 mt-3"
            >
              <Select
                className="pagination-btn"
                value={dataPerPage}
                onChange={handleChangeDataPerPage}
              >
                <MenuItem value={5}>5 Data Per Page</MenuItem>
                <MenuItem value={10}>10 Data Per Page</MenuItem>
                <MenuItem value={20}>20 Data Per Page</MenuItem>
                <MenuItem value={regData.length}>All Data Per Page</MenuItem>
              </Select>
            </Col>
          </Row>
        )}
        <TableContainer className="registration-table-container table-container">
          <Table style={{ borderRadius: "16px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  S.NO
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  UNIQUE ID
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  NAME
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  MOBILE NO
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  MAIL ID
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#6000d9",
                    color: "white",
                    fontFamily: "Gellix",
                    fontWeight: "bold",
                    fontSize: "1em",
                  }}
                >
                  ACTIONS
                </TableCell>
                {sessionStorage.getItem("Role") == "super-admin" && (
                  <TableCell
                    style={{ backgroundColor: "#6000d9", color: "white" }}
                  >
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAll}
                      inputProps={{ "aria-label": "select all" }}
                    />
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((reg, index) => (
                <>
                  <TableRow key={reg.user_id} className="table-row">
                    <TableCell className="table-cell-value">
                      {index + 1}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {reg.unique_id}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {reg.user_name}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {reg.phone_no}
                    </TableCell>
                    <TableCell className="table-cell-value">
                      {reg.email}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => handleActions(e, reg.user_id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedId === reg.user_id}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={() => handleView(reg.user_id)}>
                          <FaEye />
                          &nbsp;&nbsp; View
                        </MenuItem>
                        <MenuItem onClick={() => handleUpdate(reg.user_id)}>
                          <FaEdit />
                          &nbsp;&nbsp; Edit
                        </MenuItem>
                        {sessionStorage.getItem("Role") == "super-admin" && (
                          <MenuItem onClick={() => handleDlt(reg.user_id)}>
                            <BsTrash />
                            &nbsp;&nbsp; Delete
                          </MenuItem>
                        )}
                      </Menu>
                    </TableCell>
                    {sessionStorage.getItem("Role") == "super-admin" && (
                      <TableCell>
                        <Checkbox
                          checked={selectedRows.includes(reg.user_id)}
                          onChange={() => handleSelectRow(reg.user_id)}
                          inputProps={{ "aria-label": `select ${reg.user_id}` }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Row className="mt-3">
          <Col xs={12} md={6} lg={5} className="d-flex justify-content-start">
            <h5>
              Total number of visitors registered -{" "}
              <span>{regData.length}</span>
            </h5>
          </Col>
          <Col xs={12} md={6} lg={6} className="d-flex justify-content-start">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(filteredDatas.length / dataPerPage)}
                page={currentPage}
                onChange={(e, value) => setCurrentPage(value)}
              />
            </Stack>
          </Col>
        </Row>
      </Container>

      <Dialog open={openScore} onClose={() => setOpenScore(false)}>
        <DialogTitle>Add Score</DialogTitle>
        <DialogContent>
          <input
            type="number"
            placeholder="Enter Score"
            value={scoreData.score}
            onChange={handleScoreChange} // Handle score input change
            style={{ width: "100%", padding: "10px", marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenScore(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleScoreSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openViewUser}
        onClose={() => setOpenViewUser(false)}
        maxWidth="lg"
        
        sx={{
          backdropFilter: "blur(10px)", 
          border: "1px solid rgba(255, 255, 255, 0.3)", 
          borderRadius: "1rem",
        }}
      >
        <DialogContent  className="admin-edit-registeration-indexpage">
          <ViewUser data={viewUserData} />
        </DialogContent>
        <DialogActions  className="admin-edit-registeration-indexpage">
          <button onClick={() => setOpenViewUser(false)} 
             className="admin-edit-registeration-indexpage-close-btn"
            >Close</button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdate}
        onClose={() => setOpenUpdate(false)}
        maxWidth="lg"
        sx={{
          backdropFilter: "blur(10px)", 
          borderRadius: "1rem",
        }}
      >
        <DialogContent className="admin-edit-registeration-indexpage">
          <UpdateRegistrationForm
            userData={updateData}
            handleUpdateSuccess={handleUpdateSuccessMsg}
          />
        </DialogContent>
        <DialogActions className="admin-edit-registeration-indexpage">
          <button
            onClick={() => setOpenUpdate(false)}
            className="admin-edit-registeration-indexpage-close-btn"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDlt} onClose={() => setOpenDlt(false)}>
        <DialogContent>
          <p>Are you sure do you want to delete this user data?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmDlt}
            style={{ backgroundColor: "red", color: "white" }}
          >
            Delete
          </Button>
          <Button
            onClick={() => setOpenDlt(false)}
            style={{ backgroundColor: "green", color: "white" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegistrationIndex;
