import React from 'react'
import NavigationBar from '../NavigationBar/NavigationBar'
import RegistrationIndex from './RegistrationIndex'

const RegistrationIndexRouting = () => {
  return (
    <div>
      <NavigationBar/>
      <RegistrationIndex/>
    </div>
  )
}

export default RegistrationIndexRouting
