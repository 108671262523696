import { Link, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import React from 'react';

const TermsAndConditions = () => {
  return (
    <Box sx={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <Typography variant="h4" component="h4" gutterBottom>
        Grabow Game Rules & Regulations
      </Typography>

      <Box sx={{ marginTop: '30px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Eligibility
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Participants must be at least 18 years of age." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The game is open to all visitors of the Kama Capital LLC booth at the expo." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Game Mechanics
        </Typography>
        <List>
          <ListItem >
            <ListItemText primary="Participants will stand in a designated area to catch sticks that fall one by one." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The number of sticks caught by the participant will be recorded." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The top 5 participants who catch the most sticks will each receive a giveaway." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Participants must catch at least three stick's to be eligible for a giveaway." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Tie-Breaker Rule
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="In the event that multiple participants catch the same number of sticks, a death round will be conducted by the end of the expo day." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The participant who catches the most sticks in the sudden-death round will be declared the winner." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If a tie persists, the process will be repeated until the top 5 participants are determined." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Giveaways
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="The top 5 participants based on the number of sticks caught will receive a giveaway." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Prizes are non-transferable and cannot be exchanged for cash or other items." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Winners must be physically present at the booth on the same day to collect their prizes." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Representatives cannot be sent to collect prizes on behalf of the winners." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Participation
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Each participant is allowed one attempt per day." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Participants must register at the booth before playing the game." />
          </ListItem>
          <ListItem>
            <ListItemText primary="By participating, participants agree to share their photo on Kama Capital LLC’s social media platforms." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Participants also agree to be contacted by a company representative who will promote Kama Capital LLC's services." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Safety Guidelines
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Participants must follow the instructions provided by the game supervisor." />
          </ListItem>
          <ListItem>
            <ListItemText primary="The game area must be kept clear of obstacles." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Any participant not adhering to the safety guidelines will be disqualified." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Disqualification
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Participants will be disqualified for not following the game rules." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Attempting to catch sticks outside the designated area." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Engaging in disruptive behaviour." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Liability
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Kama Capital LLC is not responsible for any injuries sustained during participation in the game." />
          </ListItem>
          <ListItem>
            <ListItemText primary="By participating, participants agree to release Kama Capital LLC from any liability related to the game." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Terms & Conditions
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="By participating in the Grabow Game, participants agree to these rules and regulations." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Kama Capital LLC reserves the right to modify the rules or cancel the game at any time without prior notice." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Any disputes will be resolved at the sole discretion of Kama Capital LLC." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="h5" component="h5" gutterBottom>
          Privacy
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Participant data collected during registration will be used solely for the purpose of the game and follow-up communications." />
          </ListItem>
          <ListItem>
            <ListItemText primary="By participating, participants agree to the collection and use of their personal information as outlined in Kama Capital LLC’s privacy policy." />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

export default TermsAndConditions;
