import React from "react";
import NavigationBar from "../NavigationBar/NavigationBar";
import ScoreIndex from "./ScoreIndex";

const ScoreIndexRouting = () => {
  return (
    <div>
      <NavigationBar />
      <ScoreIndex />
    </div>
  );
};

export default ScoreIndexRouting;
