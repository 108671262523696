import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginRouting from './Components/Login/LoginRouting';
import DashboardRouting from './Components/Dashboard/DashboardRouting';
import RegistrationIndexRouting from './Components/RegistrationIndex/RegistrationIndexRouting';
import RegistrationFormRouting from './Components/RegistrationForm/RegistrationFormRouting';
import AddScoreRouting from './Components/AddScore/AddScoreRouting';
import ScoreIndexRouting from './Components/ScoreIndex/ScoreIndexRouting';
import Unauthorized from './Components/Unauthorized/Unauthorized';
import NewRegistrationForm from './Components/RegistrationForm/NewRegistrationForm';
import LeaderBoardRouting from './Components/LeaderBoard/LeaderBoardRouting';
import QrCodeScanner from './Components/QRCode/QrCodeScanner';
import UniqueId from './Components/UniqueId/UniqueId';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loginStatus = sessionStorage.getItem("login");
    setIsLoggedIn(loginStatus === 'true');
  }, []);


  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route 
            path='/' 
            element={isLoggedIn ? <DashboardRouting /> : <LoginRouting setIsLoggedIn={setIsLoggedIn} />} 
          />
          {isLoggedIn ? (
            <>
              <Route path='/adminIndex' element={<DashboardRouting />} />
              <Route path='/regForm/:admin_id' element={<RegistrationFormRouting />} />
              <Route path='/regIndex' element={<RegistrationIndexRouting />} />
              <Route path='/addScore' element={<AddScoreRouting />} />
              <Route path='/scoreIndex' element={<ScoreIndexRouting />} />
              <Route path='/qrScaner' element={<QrCodeScanner />} />
              <Route path='/uniqueId/:uniqueId' element={<UniqueId/>} />
            </>
          ) : (
            // Show the Unauthorized component for any of these routes if not logged in
            <>
              <Route path='/adminIndex' element={<Unauthorized />} />
              <Route path='/regForm' element={<Unauthorized />} />
              <Route path='/regIndex' element={<Unauthorized />} />
              <Route path='/addScore' element={<Unauthorized />} />
              <Route path='/scoreIndex' element={<Unauthorized />} />
              <Route path='/testinggs' element={<NewRegistrationForm />} />
              {/* <Route path='/leaderBoard' element={<LeaderBoardRouting />} /> */}
            </>
          )}
          
          <Route path='/leaderBoard' element={<LeaderBoardRouting />} />
          <Route path='/uniqueId/:uniqueId' element={<UniqueId/>} />
          <Route path='/userReg/:adminId' element={<RegistrationFormRouting />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
