import React from 'react'
import NavigationBar from '../NavigationBar/NavigationBar'
import Dashboard from './Dashboard'
import RegisterationFooter from '../RegisterationFooter/RegisterationFooter'
import "./DashboardRouting.css"

const DashboardRouting = () => {
  return (
    <div className='dashboardRouting-index-page'>
      <NavigationBar/>
      <Dashboard/>
      <div className='dashboard-registeration-footer-rotingpage'>
        <RegisterationFooter />
      </div>
    </div>
  )
}

export default DashboardRouting
