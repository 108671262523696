import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import sliderVdo from '../Images/leader-board/leader-board-vdo-new.mp4'
import moment from 'moment';
import LeaderBoardIndex from './LeaderBoardIndex';
import NavigationBar from '../NavigationBar/NavigationBar';
import kamaIcon from '../Images/logo.png'
import './LeaderBoardRouting.css'
import Marquee from "react-fast-marquee";
import NewLeaderBoardNavbar from './NewLeaderBoardNavbar/NewLeaderBoardNavbar';

const LeaderBoardRouting = () => {
  const calculateTimeRemaining = () => {
    const now = moment(); // Current time
    const startTime = moment().set({ hour: 10, minute: 0, second: 0 }); // 10:00 AM today
    const endTime = moment().set({ hour: 18, minute: 0, second: 0 }); // 6:00 PM today

    // Case 1: If it's before 10 AM, show the full 8 hours
    if (now.isBefore(startTime)) {
      return endTime.diff(startTime);
    }

    // Case 2: If it's between 10 AM and 6 PM, calculate the remaining time until 6 PM
    if (now.isBetween(startTime, endTime)) {
      return endTime.diff(now);
    }

    // Case 3: If it's after 6 PM, set the timer to 0
    return 0;
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timerInterval);
  }, []);

  // Calculate hours, minutes, and seconds from milliseconds
  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  // Format time as HH:mm:ss
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;


  return (
    <div className='leader-board-routing-section'>
      {/* <NavigationBar/> */}
      <NewLeaderBoardNavbar/>
      <Row className='mt-5'>
        <Col xs={12} className='d-flex justify-content-center'>
        <div className='timer-container'>
          <h1>GRABOW CHALLENGE ENDS IN : {formattedTime}</h1>
        </div>
        </Col>
      </Row>
        <div className='leader-board-table-vdo-row'>
        <Container fluid>
        <Row className='mt-5'>
          <Col md={5} lg={6}>
          <LeaderBoardIndex/>
          </Col>
          {/* <Col md={1} lg={2}></Col> */}
          <Col md={5} lg={6}>
          <video
  className='leader-board-slider-vdo ms-5'
  controls
  src={sliderVdo}
  loop
  autoPlay
  muted /* To prevent autoplay sound if needed */
  playsInline /* Ensure it works on mobile */
/>
          </Col>
          </Row>
        </Container>
        </div>
        <div className='leader-board-footer-container mt-2'>
  <Marquee pauseOnHover={true} speed={60} gradient={false}>
    {/* First set: Image + Text */}
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text'>Collaborate For Global Market Excellence</h1>
    </div>

    {/* Second set: Image + Text */}
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text'>Navigating Markets With Precision</h1>
    </div>

    {/* Third set: Image + Text */}
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text'>Access Global Markets With an Adaptive Fintech Institution</h1>
    </div>
    <div className='marquee-item'>
      <img src={kamaIcon} className='leader-board-footer-kama-icon' alt="Kama Icon"/>
      <h1 className='leader-board-footer-text'>www.kama-capital.com</h1>
    </div>
  </Marquee>
</div>


    </div>
  )
}

export default LeaderBoardRouting


