import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./RegistrationContact.css";
import { RiTwitterXLine } from "react-icons/ri";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";

const RegistrationContact = () => {
  return (
    <div className="registration-contact-section-new">
      <Container>
        <div className="registration-contact">
          <Row>
            <Col xs={12} md={6} lg={6}>
              <h1 className="contact-heading">Let’s talk</h1>
              <h4>Are you a current or new user looking to trade?</h4>
              <h5>
                We’re available 24/5 to address your queries and assist with any
                concerns.
              </h5>

              <h3 className="contact-heading mt-4">Registered Office</h3>
              <Row>
                <Col xs={12} className="d-flex">
                  <FaMapMarkerAlt className="mt-2" color="white" />
                  <p className="ms-2">
                    {" "}
                    Beachmont Business Centre, 419,
                    <br /> Kingstown, <br /> St. Vincent and the Grenadines.
                  </p>
                </Col>
                <Col xs={12} className="d-flex">
                  <FaMapMarkerAlt className="mt-2" color="white" />
                  <p className="ms-2">
                    {" "}
                    3 Emerald Park,<br/> Trianon, Quatre Bornes 72257, <br/>
                    Republic of Mauritius
                  </p>
                </Col>
              </Row>
              <h3 className="contact-heading mt-md-3">Social Media</h3>
              <Row >
                <Col xs={8} md={8} lg={6} className="mt-lg-0 mt-md-0 mt-2">
                  <div className="social-media-icons">
                    <a
                      href="https://x.com/kamaCapital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RiTwitterXLine className="reg-contact-icons" />
                    </a>
                    <a
                      href="https://www.facebook.com/kamaCapital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebookF className="reg-contact-icons" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/kamacapital/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn className="reg-contact-icons" />
                    </a>
                    <a
                      href="https://www.instagram.com/kamaCapital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram className="reg-contact-icons" />
                    </a>
                    <a
                      href="https://www.youtube.com/@kamacapital"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaYoutube className="reg-contact-icons" />
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} lg={6} className="mt-lg-0 mt-md-0 mt-4">
              <div>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3743.069521526203!2d57.49200257438361!3d-20.255951298539628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s3%20Emerald%20Park%2C%20Trianon%20.%20Quatre%20Bornes%2072257%20Republic%20of%20Mauritius!5e0!3m2!1sen!2sin!4v1725443761176!5m2!1sen!2sin"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  width="100%"
                  height="245px"
                ></iframe>
              </div>

              <h3 className="contact-heading mt-3">Contact</h3>

              <Row>
                <Col xs={10}>
                  <FaMapMarkerAlt color="white"/>
                  <a
                    href="tel:+18446202340"
                    className="contact-phone-icon ms-2"
                  >
                    +1(844) 620-2340
                  </a>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={10}>
                  <FaMapMarkerAlt color="white"/>
                  <a
                    href="tel:+97142258176"
                    className="contact-phone-icon ms-2"
                  >
                    +971 4225 8176
                  </a>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={10}>
                  <FaEnvelope color="white"/>
                  <a
                    href="mailto:info@kama-capital.com"
                    className="contact-phone-icon ms-3"
                  >
                    info@kama-capital.com
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default RegistrationContact;
