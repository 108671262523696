import React, { useState } from 'react';
import { Col, Container, Row,Modal, Button  } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import { FiLogOut } from 'react-icons/fi';
import { FiHome } from 'react-icons/fi';
import { useParams } from 'react-router-dom'
const Dashboard = () => {

  const admin_name = sessionStorage.getItem('admin_name');
  const admin_id = sessionStorage.getItem('admin_id');
  console.log("Admin Id",admin_id)
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = '/'; // Redirect to login page after logout
  };

  const toggleLogoutModal = () => {
    setShowConfirmLogout(!showConfirmLogout);
  };
  return (
    <div className="dashboard-container">
      <Row>
        <Col xs={12} className='d-flex justify-content-end'>
        <div className='logout-icon me-5' onClick={toggleLogoutModal}>
        <div className='d-flex justify-content-center'>
        <FiLogOut   />
        </div>
        <h6 className='text-dark'>Logout</h6>
        </div>
        </Col>
      </Row>
      <Container className='mt-5'>
        <h1 className="text-center">Welcome to the Admin Dashboard!</h1>
        <p className="text-center mt-3 admin-header-name text-white animated-text">
          Hello <span className="login-admin-name">{admin_name}!</span>
        </p>

        {sessionStorage.getItem("Role") == "super-admin" ? (
          <Row className="justify-content-center">
            <Col xs={12} md={3} lg={3}>
              <Link to={`/regForm/${admin_id}`} className="button-link-page">
                <button className="dashboard-button">New Registration</button>
                <div className="hover-info">Quickly access the registration page to add new entries.</div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/regIndex" className="button-link-page">
                <button className="dashboard-button">Registration Index</button>
                <div className="hover-info">View and manage all registered participants at a glance.</div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/addScore" className="button-link-page">
                <button className="dashboard-button">Update Score</button>
                <div className="hover-info">Input and update participant scores effortlessly.</div>
              </Link>
            </Col>

            <Col xs={12} md={3} lg={3}>
              <Link to="/scoreIndex" className="button-link-page">
                <button className="dashboard-button">Score Index</button>
                <div className="hover-info">View and manage all user scores at a glance.</div>
              </Link>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-center">
            <Col xs={12} md={4} lg={4}>
              <Link to={`/regForm/${admin_id}`} className="button-link-page">
                <button className="dashboard-button">New Registration</button>
                <div className="hover-info">Quickly access the registration page to add new entries.</div>
              </Link>
            </Col>

            <Col xs={12} md={4} lg={4}>
              <Link to="/regIndex" className="button-link-page">
                <button className="dashboard-button">Registration Index</button>
                <div className="hover-info">View and manage all registered participants at a glance.</div>
              </Link>
            </Col>

            <Col xs={12} md={4} lg={4}>
              <Link to="/addScore" className="button-link-page">
                <button className="dashboard-button">Update Score</button>
                <div className="hover-info">Input and update participant scores effortlessly.</div>
              </Link>
            </Col>
          </Row>
        )}
      </Container>

       {/* Logout Confirmation Modal */}
       <Modal show={showConfirmLogout} onHide={toggleLogoutModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to log out?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleLogoutModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Dashboard;
