import React from 'react';
import './Unauthorized.css';
import { Col, Container, Row } from 'react-bootstrap';

const Unauthorized = () => {
  return (
    <div className="unauthorized-section">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12}>
            <h1>Unauthorized Access</h1>
            <p>You do not have permission to view this page. Please log in to continue.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Unauthorized;
