import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Left from "../Images/Polygon 1.png";
import Right from "../Images/Polygon 2.png";
import "./RegistrationStripe.css";

function RegistrationStripe() {
  return (
    <div className="registration-stripe-section">
      <Row className="align-items-center">
        <Col xs={2} sm={2} md={2} lg={2} className="d-flex align-items-center">
          <img src={Left} alt="strip-left-logo" className="reg-stripe-left-img" />
        </Col>
        <Col xs={8} sm={8} md={8} lg={8} className="text-center">
          <h2 className="strip-text">COLLABORATE  FOR  GLOBAL  MARKET  EXCELLENCE</h2>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} className="d-flex justify-content-end align-items-center">
          <img src={Right} alt="strip-right-logo" className="reg-stripe-right-img" />
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStripe;
