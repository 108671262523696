import React from 'react'
import NavigationBar from '../NavigationBar/NavigationBar'
import AddScore from './AddScore'

const AddScoreRouting = () => {
  return (
    <div>
      <NavigationBar/>
      <AddScore/>
    </div>
  )
}

export default AddScoreRouting
