import React, { useState } from 'react';
import "./UniqueId.css";
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import NavigationBar from '../NavigationBar/NavigationBar';
import { FaCopy } from 'react-icons/fa';
import { useNavigate, useParams  } from 'react-router-dom';


function UniqueId() {
  const { uniqueId } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem('login') == 'true');
  const admin_id = isLoggedIn && sessionStorage.getItem('admin_id')
    // const [uniqueId , setUniqueId] = useState();
    const [isCopied, setIsCopied] = useState(false);
    const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText(uniqueId);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderTooltip = (props) => (
    <Tooltip id="copy-tooltip" {...props}>
      {isCopied ? "Copied!" : "Copy"}
    </Tooltip>
  );

  const handleHome = () => {
    navigate('/adminIndex')
  }
  const handleNavigateNewRegistration = () => {
    navigate(`/regForm/${admin_id}`)
  }

  return (
    <>
      <div className='register-unique-id-generated'>
        <NavigationBar />
        <Container className='register-unique-id-generated-box w-50 w-md-50 w-lg-50  '>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} md={6} lg={8} xl={8} className="text-center">
            <h3 style={{color:'green'}}>Registered Successfully</h3>
              <h1 className='mb-5'>Your unique id</h1>
              <div className="d-flex justify-content-between align-items-center registeration-show-unique-id">
                <h3 className="p-3 mb-0 flex-grow-1 text-center">{uniqueId}</h3>
                <div className="text-end unique-id-copie-icon ">
                    <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip}
                    >
                    <FaCopy
                        size={20}
                        className="cursor-pointer"
                        onClick={handleCopy}
                        title="Copy to clipboard"
                    />
                    </OverlayTrigger>
                </div>
                </div>
              {isCopied && <p className="mt-2 text-success">Copied your unique ID!</p>}
            </Col>
            </Row>

            {sessionStorage.getItem('login') == 'true' && (
              <div className='d-flex text-center justify-content-center mt-5'>
              <button className='unique-id-button' onClick={handleHome} >Home</button>
              <button className='unique-id-button ms-5' onClick={handleNavigateNewRegistration}>Registration</button>
              </div>
            )}
        </Container>
      </div>
    </>
  );
}



export default UniqueId;
