import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './LeaderBoardIndex.css'
import { Container } from 'react-bootstrap';

const LeaderBoardIndex = () => {
  const [leaderData, setLeaderData] = useState([]);

  const fetchLeaderboardData = async () => {
    try {
      const response = await axios.get('https://ifx-expo-server.xicsolutions.in/score/leaderboarddata');
      const sortedData = response.data
        .sort((a, b) => {
          if (b.score === a.score) {
            return a.unique_id.localeCompare(b.unique_id);
          }
          return b.score - a.score;
        });
      setLeaderData(sortedData);
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message, { autoClose: 3000 });
      } else {
        toast.error("Please wait or contact your support team.", { autoClose: 3000 });
      }
    }
  };

  useEffect(() => {
    // Fetch the leaderboard data initially
    fetchLeaderboardData();

    // Set up polling every 10 seconds
    const intervalId = setInterval(() => {
      fetchLeaderboardData();
    }, 10000); // Fetches new data every 10 seconds

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const top5 = leaderData.slice(0, 5);

  return (
    <div className='leader-board-index-setion'>
      <ToastContainer />
       <Container fluid>
       <TableContainer className="table-container leader-board-index-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className='headerStyle '>RANK</TableCell>
                <TableCell className='headerStyle '>PHOTO</TableCell>
                <TableCell className='headerStyle '>SCORE</TableCell>
                <TableCell className='headerStyle '>NAME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {top5.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className='leader-board-index-table-cell'>{index + 1}</TableCell> {/* Ranks start from 1 */}
                  <TableCell className='leader-board-index-table-cell'>
                    <img
                      src={`https://ifx-expo-server.xicsolutions.in/uploads/${row.user_img}`}
                      alt={row.user_name}
                      className='leader-board-user-image'
                      
                    />
                  </TableCell>
                  <TableCell className='leader-board-index-table-cell'>{row.score}</TableCell>
                  <TableCell className='leader-board-index-table-cell'>{row.user_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
       </Container>
    </div>
  );
};

export default LeaderBoardIndex;
