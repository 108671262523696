import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import ifx_logo from '../../Images/Logos/ifx_expo_asia.webp';
import kama_logo from '../../Images/Logos/kama-main-logo.png';
import './NewLeaderBoardNavbar.css';

const NewLeaderBoardNavbar = () => {
 
  return (
    <>
      <Navbar variant="light" className="leader-board-navigation-bar-section">
        <Container fluid>
          {/* Left Image */}
          <Navbar.Brand href="#">
            <img
              src={kama_logo}
              alt="Left Image"
              className="d-inline-block align-top leader-board-navigation-bar-kama-logo"
            />
          </Navbar.Brand>

          {/* Right Side - IFX Logo and Logout Icon */}
          <div className="ms-auto d-flex align-items-center">
            <Navbar.Brand href="#">
              <img
                src={ifx_logo}
                alt="Right Image"
                className="d-inline-block align-top leader-board-navigation-bar-ifx-logo"
              />
            </Navbar.Brand>

          </div>
        </Container>
      </Navbar>

      
    </>
  );
};

export default NewLeaderBoardNavbar;
