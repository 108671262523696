import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import Login from './Login';

const LoginRouting = ({ checkLogin }) => {
  return (
    <div className="  ">
      <NavigationBar />
      <Login checkLogin={checkLogin} />
    </div>
  );
}

export default LoginRouting;
