import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import './QrCodeScanner.css';
import { QRCode } from 'react-qrcode-logo';
import KamaCaptitalLogo from '../Images/kamacaptital.jpg';


function QrCodeScanner() {
  const isLoggedIn = sessionStorage.getItem('login') === 'true';
  
  // Admin ID from sessionStorage
  const adminId = isLoggedIn ? sessionStorage.getItem('admin_id') : null;
  console.log("Admin Id",adminId)
  return (
    <>
      <div className='qr-registeration-index-page text-center'>
        <NavigationBar />
        <div className='container mt-5'>
          <QRCode
            value={`https://ifxbkk24grabow.kama-capital.com/userReg/${adminId}`}
            // value={`https://ifx-expo.xicsolutions.in/userReg/${adminId}`}
            logoImage={KamaCaptitalLogo}
            size={400}
            logoWidth={70}
            logoHeight={70}
            style={{ borderRadius: '1rem', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)' }}
            
            logoBorderRadius={50} 
            qrStyle="dots" 
            e eyeRadius={[
              { outer: [10, 10, 0, 10], inner: [10 , 10 , 0 , 10] }, 
              { outer: [10, 10, 10, 0], inner: [10 , 10 , 10 , 0] },
              { outer: [10, 0, 10, 10], inner: [10 , 0 , 10 , 10] } 
            ]}
            eyeColor="#410087" 
            fgColor="#6000d2"
           
          />
        </div>
      </div>
    </>
  );
}

export default QrCodeScanner;
